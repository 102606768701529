import styled from '@emotion/styled'
import { WppTypography } from '@platform-ui-kit/components-library-react'

import { OS_HEADER_HEIGHT } from 'hooks/useOnScreen'
import { Flex } from 'ui-base/flex/Flex'

export const IntroPlaceholder = styled('div', { shouldForwardProp: prop => prop !== 'height' })<{
  height: number
}>`
  height: ${({ height }) => `${height + 64}px`};
  width: 100%;
`

export const SearchPlaceholder = styled('div')`
  height: 40px;
  width: 100%;
`

export const Wrapper = styled(Flex)`
  position: absolute;
  width: 100%;
  min-height: 214px;
  background-color: var(--wpp-primary-color-500);
  border-radius: 16px;
  padding: 32px 49px;
  z-index: 2;
`

export const SearchWrapper = styled('div', { shouldForwardProp: prop => prop !== 'isCollapsed' })<{
  isCollapsed?: boolean
}>`
  background-color: var(--wpp-grey-color-100);
  position: ${({ isCollapsed }) => (isCollapsed ? 'sticky' : 'absolute')};
  width: 100%;
  // 88px = 40px height of searchbar + 32px top padding + 16px bottom padding
  margin: ${({ isCollapsed }) => (isCollapsed ? '0 -16px -88px' : '0')};
  padding: ${({ isCollapsed }) => (isCollapsed ? '32px 16px 16px' : '0')};
  box-sizing: content-box;
  top: ${({ isCollapsed }) => (isCollapsed ? `${OS_HEADER_HEIGHT}px` : '')};
  z-index: 2;
  overflow: visible;
  animation: fadeIn ease-in 0.2s forwards;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: calc(100% - 32px);
    border-bottom: ${({ isCollapsed }) => (isCollapsed ? '1px solid var(--wpp-grey-color-400)' : 'none')};
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }

  .search {
    .hint {
      margin-top: 7px;
    }
  }
`

export const SectionContent = styled(Flex)`
  width: 62%;
  max-width: 647px;
  align-self: center;

  @media (max-width: ${({ theme }) => theme?.breakpoints.values.md}px) {
    width: 100%;
  }
`

export const SectionTitle = styled(WppTypography)`
  color: var(--wpp-white-color);
`

export const SectionImageWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: linear-gradient(
      93deg,
      rgb(from var(--wpp-primary-color-500) r g b / 95%) 68.09%,
      rgb(from var(--wpp-primary-color-500) r g b / 55%) 110.89%
    );
    mix-blend-mode: multiply;
    z-index: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    display: none;
  }
`

export const Image = styled('div', { shouldForwardProp: prop => prop !== 'url' })<{
  url?: string
}>`
  height: 100%;
  width: 72%;
  background-image: ${({ url }) => `url('${url}')`};
  background-position: left 58%;
  background-size: cover;
  background-repeat: no-repeat;
`
