import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const ApplicationIconSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5714 13.5H6.42857C5.36336 13.5 4.5 12.6366 4.5 11.5714V6.42857C4.5 5.36336 5.36336 4.5 6.42857 4.5H11.5714C12.6366 4.5 13.5 5.36336 13.5 6.42857V11.5714C13.5 12.6366 12.6366 13.5 11.5714 13.5Z"
      className="fillPrimary400 strokePrimary400"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6193 3.60926L26.3326 7.16733C27.1018 7.9043 27.1279 9.12499 26.3909 9.89412L22.8328 13.6075C22.0958 14.3766 20.8752 14.4027 20.106 13.6657L16.3927 10.1076C15.6235 9.37068 15.5975 8.14999 16.3344 7.38086L19.8925 3.66749C20.6295 2.89836 21.8502 2.87229 22.6193 3.60926Z"
      className="fillPrimary200 strokePrimary200"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7589 25.5625H6.61607C5.55086 25.5625 4.6875 24.6991 4.6875 23.6339V18.4911C4.6875 17.4259 5.55086 16.5625 6.61607 16.5625H11.7589C12.8241 16.5625 13.6875 17.4259 13.6875 18.4911V23.6339C13.6875 24.6991 12.8241 25.5625 11.7589 25.5625Z"
      className="fillPrimary400 strokePrimary400"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6339 25.5625H18.4911C17.4259 25.5625 16.5625 24.6991 16.5625 23.6339V18.4911C16.5625 17.4259 17.4259 16.5625 18.4911 16.5625H23.6339C24.6991 16.5625 25.5625 17.4259 25.5625 18.4911V23.6339C25.5625 24.6991 24.6991 25.5625 23.6339 25.5625Z"
      className="fillPrimary400 strokePrimary400"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.CustomSvg>
)
