import { ApplicationIconSvg } from 'ui-base/svgIcons/logo/assets/ApplicationIconSvg'

import { LogoPlaceholderWrapper } from 'ui-base/svgIcons/logo/Logo.styled'

interface Props {
  size?: 'xs' | 's' | 'm' | 'l'
  isCircle?: boolean
}

export const LogoPlaceholder = ({ size, isCircle, ...rest }: Props) => (
  <LogoPlaceholderWrapper justify="center" align="center" size={size} isCircle={isCircle} {...rest}>
    <ApplicationIconSvg width="70%" height="70%" />
  </LogoPlaceholderWrapper>
)

export * from 'ui-base/svgIcons/logo/Logo.styled'
