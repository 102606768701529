import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  WppAccordion,
  WppActionButton,
  WppCard,
  WppCardGroup,
  WppCheckbox,
  WppDivider,
  WppGrid,
  WppIconArrow,
  WppInput,
  WppTree,
  WppTypography,
} from '@platform-ui-kit/components-library-react'

import { ApplicationSelectableCardsFilter } from 'pages/applications/list/components/ApplicationSelectableCardsFilter'
import { Flex } from 'ui-base/flex/Flex'
import { Tooltip } from 'ui-base/tooltip/Tooltip'

export const Wrapper = styled(Flex)`
  width: 100%;
  position: relative;
`

export const SearchWrapper = styled('div', { shouldForwardProp: props => props !== 'isFullwidthSearch' })<{
  isFullwidthSearch: boolean
}>`
  width: 100%;
  max-width: ${({ isFullwidthSearch }) => (isFullwidthSearch ? 'unset' : '100%')};
  position: relative;
`

export const SearchInput = styled(WppInput)`
  &::part(input) {
    background-color: var(--wpp-white-color);
  }
`

export const ApplicationsSection = styled('section', { shouldForwardProp: props => props !== 'hasFilters' })<{
  hasFilters?: boolean
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ hasFilters }) => (hasFilters ? '0' : '48px')};
`

export const SectionDescriptionText = styled(Flex)`
  flex-grow: 1;
`

export const CategoryDescription = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
  width: 55%;
  margin-top: 4px;
`

export const ActionButton = styled(WppActionButton)`
  ::part(button) {
    color: var(--wpp-primary-color-500);
  }
`

export const IconArrow = styled(WppIconArrow)`
  color: var(--wpp-primary-color-500);
`

const commonVariablesOverride = css`
  --fullWidth: calc(100% * 24 / 18);
  --onePercentRaw: calc(var(--fullWidth) / 100);
  --wpp-grid-max-spacing: var(--onePercentRaw);
`

export const ApplicationCardsWrapper = styled(WppGrid)`
  margin-top: 24px;

  ${commonVariablesOverride}
`

export const SpinnerBody = styled(Flex)`
  margin: 24px 0;
`

export const DataBannerWrapper = styled(WppGrid)`
  width: 100%;
  //multiply by 2 to get the same padding we have between cards
  padding: calc(var(--grid-vertical-spacing) * 2) 0 0 0;

  ${commonVariablesOverride}
`

export const DataBannerCard = styled(WppCard)`
  height: 200px;

  ::part(card) {
    background-color: var(--wpp-primary-color-100);
    padding: 0;
  }
`

export const DataBannerInfo = styled(Flex)`
  padding: 20px 0 24px 24px;
`

export const DataBannerDescription = styled(WppTypography)`
  max-width: 569px;

  ::part(typography) {
    color: var(--wpp-grey-color-1000);
    font-size: 16px;
    line-height: 24px;
  }
`

const commonSearchStyles = css`
  position: absolute;
  top: 47px;
  background: var(--wpp-white-color);
  color: var(--wpp-grey-color-800);
  width: 100%;
  box-shadow: 0 1px 4px rgba(52, 58, 63, 0.05), 0 4px 12px rgba(52, 58, 63, 0.12);
  border-radius: 6px;
  z-index: 10;
`

export const Hint = styled(WppTypography)`
  height: 40px;
  padding: 9px 16px;

  ${commonSearchStyles}
`

export const QuickResults = styled('ul')`
  max-height: 300px;

  ${commonSearchStyles}
`

export const ApplicationSearchRow = styled('li')`
  height: 60px;
  padding: 9px 16px;
  overflow: hidden;

  &:hover {
    background-color: var(--wpp-text-color-white-info);
    cursor: pointer;
    border-radius: 6px;
  }

  &:not(:last-child) {
    padding-bottom: 10px;
  }
`

export const ApplicationName = styled(WppTypography)`
  color: var(--wpp-grey-color-1000);
  margin-bottom: 2px;
`

export const ApplicationDescription = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const CanHaveMatch = styled('span')`
  b {
    font-weight: bold;
  }
`

export const ScrollableFiltersBlockArea = styled('div')`
  max-height: 400px;
  overflow-y: auto;
`

export const EmptySearch = styled(WppTypography)`
  margin: 12px 0;
`

export const GlobalCheckbox = styled(WppCheckbox)`
  margin-left: 36px;
`

export const GlobalFilterDivider = styled(WppDivider)`
  margin: 12px 0 5px;
`

export const MarketsDescriptionTooltip = styled(Tooltip)`
  height: 20px;
`

export const RegionMarketTree = styled(WppTree)`
  margin-left: -4px;
  &::part(tree-empty-text) {
    text-align: left;
    padding: 10px 0;
  }
  &::part(tree-item) {
    background: var(--wpp-grey-color-100);
    max-width: 224px;
  }
  &::part(tree-item-title-wrapper) {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &::part(content) {
    max-height: 318px;
    overflow-y: auto;
  }
`

export const CheckboxCardGroup = styled(WppCardGroup)`
  gap: 8px;
  flex-wrap: wrap;
`

export const CheckboxCard = styled(WppCard)`
  min-height: 41px;
  flex-grow: 1;
  max-width: 49%;

  @media (max-width: 1435px) {
    width: 138px;
  }

  @media (min-width: 1436px) and (max-width: 1919px) {
    width: 156px;
  }

  @media (min-width: 1920px) {
    width: 136px;
    max-width: 32%;
  }

  &::part(card) {
    padding: 9px;
  }
`

export const CheckboxCardLabel = styled(WppTypography)`
  display: flex;
  justify-content: center;
  color: var(--wpp-grey-color-1000);
`

export const CategoryFilter = styled(ApplicationSelectableCardsFilter)`
  .category-card {
    width: calc(33% - 16px);
    min-width: 100px;
    height: 103px;
    &::part(card) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const CategoryIcon = styled('img')`
  width: 32px;
  height: 32px;
  object-fit: contain;
`

export const CategoryLabel = styled(WppTypography)`
  color: var(--wpp-grey-color-1000);
  text-align: center;
  max-width: 65px;
  word-break: break-word;
`

export const FiltersAccordion = styled(WppAccordion)`
  &::part(content) {
    margin: 6px 0 15px 0;
  }
`

export const FilterBody = styled(Flex)`
  width: 100%;
`

export const FilterControlWrapper = styled('div')`
  padding-left: 0;
`
