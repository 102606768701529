import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useCallback, useEffect, useState } from 'react'

import { ApplicationDTO } from 'types/applications/application'
import { Logo, LogoPlaceholder, LogoProps, LogoWrapper, LogoSize } from 'ui-base/svgIcons/logo/Logo'

interface Props extends Pick<ApplicationDTO, 'logoMeta'>, Pick<LogoProps, 'size' | 'isCircle'> {}

export const ApplicationLogoPlain = ({ logoMeta, size = 's' }: Props) => {
  const [imageSource, setImageSource] = useState<string>('')
  const logoUrl = logoMeta.thumbnail?.downloadUrl || logoMeta.original?.downloadUrl

  const onLoad = useCallback(() => {
    setImageSource(logoUrl)
  }, [logoUrl])

  useEffect(() => {
    if (!logoUrl) {
      return
    }
    const imageElement = new Image()
    imageElement.src = logoUrl
    imageElement.addEventListener('load', onLoad)
    return () => {
      imageElement.removeEventListener('load', onLoad)
    }
  }, [imageSource, logoUrl, onLoad])

  if (!logoUrl) {
    return <LogoPlaceholder size={size} data-testid="logo-placeholder" />
  }

  return (
    <LogoWrapper size={size}>
      {!imageSource && <WppSkeleton animation width={LogoSize[size]} height={LogoSize[size]} />}
      {!!imageSource && <Logo src={imageSource} size={size} alt="Application logo" data-testid="product-logo-img" />}
    </LogoWrapper>
  )
}
