import { PropsWithChildren, useEffect } from 'react'
// import { useTranslation } from 'react-i18next'
import { useMeasure, useSetState } from 'react-use'

import { useOnScreen } from 'hooks/useOnScreen'
import IntroImage from 'ui-shared/catalogueIntroSection/assets/heroImageUpdated.png'

import * as S from 'ui-shared/catalogueIntroSection/CatalogueIntroSection.styled'

interface Props {
  title?: string
}

const defaultIntroHeight = 150 // default height of the element to pass to useMeasure so that page content doesn't shift on first render

export const CatalogueIntroSection = ({ children }: PropsWithChildren<Props>) => {
  const { isIntersecting, ref: introPlaceholderRef } = useOnScreen(true)

  const [originalIntroSize, setOriginalIntroSize] = useSetState({ width: 0, height: defaultIntroHeight })
  const [introRef, { height: introHeight }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    if (!isIntersecting) return
    setOriginalIntroSize(({ height: prevHeight }) => ({
      height: Math.max(prevHeight, introHeight),
    }))
  }, [isIntersecting, introHeight, setOriginalIntroSize])

  return (
    <>
      <S.IntroPlaceholder
        ref={introPlaceholderRef}
        height={originalIntroSize.height}
        data-testid="intro-section-banner"
      />
      {isIntersecting ? (
        <S.Wrapper ref={introRef} data-testid="intro-section">
          <S.SectionContent direction="column" gap={22}>
            {children}
          </S.SectionContent>
          <S.SectionImageWrapper justify="flex-end">
            <S.Image url={IntroImage} />
          </S.SectionImageWrapper>
        </S.Wrapper>
      ) : (
        <S.SearchWrapper isCollapsed={!isIntersecting}>{children}</S.SearchWrapper>
      )}
    </>
  )
}
