import styled from '@emotion/styled'

export const VerticalDivider = styled('div', { shouldForwardProp: prop => prop !== 'margin' && prop !== 'height' })<{
  margin?: string
  height?: number
}>`
  height: ${({ height }) => (height ? `${height}px` : '20px')};
  width: 1px;
  background-color: var(--wpp-divider-bg-color, var(--wpp-grey-color-300));
  border-radius: var(--wpp-divider-border-radius, 2px);
  margin: ${({ margin }) => (margin ? margin : '0 15px')};
  flex-shrink: 0;
`
