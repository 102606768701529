import { TreeType } from '@platform-ui-kit/components-library'
import { MayBeNull, Tenant } from '@wpp-open/core'
import { createContext, useContext, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { useMatch } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useApplicationTenantIdsApi } from 'api/applications/queries/useApplicationTenantIdsApi'
import { useMarketsApi } from 'api/applications/queries/useMarketsApi'
import { useGetAllTenantsMap } from 'api/tenants/queries/useAllTenantsApi'
import { regionMarketsArrToTree, removeMarketFromTreeState } from 'pages/applications/list/utils'
import { CatalogueRoutes } from 'pages/catalogue/main/CatalogueMainPage'
import { CommercialModel, ApplicationCategory } from 'types/applications/enums'
import { MarketDTO } from 'types/applications/market'

export type FiltersAppType = {
  categories: ApplicationCategory[]
  regions: string[]
  markets: string[]
  commercialModels: MayBeNull<CommercialModel[]>
  isAvailable: MayBeNull<boolean>
  globalRegion: MayBeNull<boolean>
  tenantIds: string[]
  productIds: string[]
  search: string
  /**
   * This field reflects value of search input when `search` field itself is set
   * only when SEARCH_QUERY_MIN_LENGTH is reached or Enter button is pressed
   *
   * searchFieldValue serves only for one purpose - to add search parameter to request
   * when search length is less than SEARCH_QUERY_MIN_LENGTH
   * but input still has value and filters are applied
   */
  searchFieldValue: string
}

export type FiltersType = {
  applicationFilters: FiltersAppType
  regionMarketsTreeState: TreeType[]
  isApplicationFiltersDirty: boolean
}

interface FiltersContextValue {
  filtersState: FiltersType
  setFiltersState: (patch: Partial<FiltersType> | ((prevState: FiltersType) => Partial<FiltersType>)) => void
  setAppFilters: (appFilters: Partial<FiltersAppType>, applySearch?: boolean) => void
  removeFilterAppMarket: (market: string) => void
  resetAppFilters: () => void
  appFilters: FiltersAppType
  markets: MarketDTO[]
  tenants: { [k: string]: Tenant }
  applicationTenants: { [k: string]: Tenant }
}

const CatalogueFiltersContext = createContext<FiltersContextValue>(null!)

export const useCatalogueFilters = () => useContext(CatalogueFiltersContext)

export const initialFilters: FiltersType = {
  applicationFilters: {
    categories: [],
    regions: [],
    markets: [],
    isAvailable: null,
    globalRegion: null,
    commercialModels: null,
    tenantIds: [],
    productIds: [],
    search: '',
    searchFieldValue: '',
  },
  isApplicationFiltersDirty: false,
  regionMarketsTreeState: [],
}

export const CatalogueFiltersProvider = ({ children }: PropsWithChildren) => {
  const isApplicationPage = useMatch(CatalogueRoutes.APPLICATIONS)
  const { data: markets } = useMarketsApi({
    enabled: !!isApplicationPage,
  })
  const tenants = useGetAllTenantsMap(!!isApplicationPage)
  const { data: applicationTenantIds } = useApplicationTenantIdsApi()

  const applicationTenants = useMemo(() => {
    if (Object.keys(tenants).length === 0 || applicationTenantIds.length === 0) {
      return {}
    }
    return Object.entries(tenants).reduce<typeof tenants>((acc, [tenantId, tenant]) => {
      if (applicationTenantIds.includes(tenantId)) {
        acc[tenantId] = tenant
      }
      return acc
    }, {})
  }, [tenants, applicationTenantIds])

  const [filtersState, setFiltersState] = useSetState<FiltersType>(initialFilters)

  const getIsDirty = (state: FiltersType, appFilters: Partial<FiltersAppType>): boolean => {
    const keysToUpdate = Object.keys(appFilters) as (keyof FiltersAppType)[]
    const isFiltersChanges = keysToUpdate.some(key => state.applicationFilters[key] !== appFilters[key])
    return isFiltersChanges || state.isApplicationFiltersDirty
  }

  const setAppFilters = useCallback(
    (appFilters: Partial<FiltersAppType>, applySearch = true) =>
      setFiltersState(prev => ({
        ...prev,
        applicationFilters: {
          ...prev.applicationFilters,
          ...appFilters,
          ...(applySearch && prev.applicationFilters.searchFieldValue
            ? { search: prev.applicationFilters.searchFieldValue }
            : {}),
        },
        isApplicationFiltersDirty: getIsDirty(prev, appFilters),
      })),
    [setFiltersState],
  )

  const removeFilterAppMarket = useCallback(
    (market: string) => setFiltersState(filtersState => removeMarketFromTreeState(filtersState)(market)),
    [setFiltersState],
  )

  const resetAppFilters = useCallback(() => {
    setFiltersState(state => ({
      ...state,
      applicationFilters: initialFilters.applicationFilters,
      regionMarketsTreeState: regionMarketsArrToTree(markets),
      isApplicationFiltersDirty: false,
    }))
  }, [setFiltersState, markets])

  useEffect(() => {
    // initial mounting
    setFiltersState({ regionMarketsTreeState: regionMarketsArrToTree(markets) })
  }, [markets, setFiltersState])

  return (
    <CatalogueFiltersContext.Provider
      value={{
        filtersState,
        setFiltersState,
        setAppFilters,
        removeFilterAppMarket,
        resetAppFilters,
        appFilters: filtersState.applicationFilters,
        markets,
        tenants,
        applicationTenants,
      }}
    >
      {children}
    </CatalogueFiltersContext.Provider>
  )
}
