import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useLocale } from 'hooks/useLocale'
import { ApplicationAllTags } from 'pages/applications/list/components/filterTags/components/ApplicationAllTags'
import { ApplicationLineOfTags } from 'pages/applications/list/components/filterTags/components/ApplicationLineOfTags'
import { FiltersAppType, useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationCategoryLabel, CommercialModelLabel } from 'types/applications/labels'
import { MayBeNull } from 'types/common/utils'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/components/filterTags/ApplicationFiltersTags.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  resultsNumber: number
  isSearchApplied: boolean
  isLoading: boolean
}

export type CategoryCode = Exclude<keyof FiltersAppType, 'search' | 'searchFieldValue'>
type IterableFilterCode = Exclude<CategoryCode, 'commercialModels' | 'isAvailable' | 'globalRegion'>

export type FilterGroupNames = 'categoryTags' | 'availabilityTags' | 'commercialModelTags' | 'marketTags' | 'tenantTags'

export interface TagLabel {
  code: string
  label: string
  category: CategoryCode
}

export type RemoveFilter = (code: CategoryCode, value: string) => void

export const ApplicationFiltersTags = ({ resultsNumber, isLoading, isSearchApplied }: Props) => {
  const { t } = useTranslation(['common', 'applications'])
  const { numberLocale } = useLocale()

  const [firstLineTagsAmount, setFirstLineTagsAmount] = useState<MayBeNull<number>>(null)
  const [isTagsExpanded, setIsTagsExpanded] = useState(false)
  const { tenants, markets, appFilters, setAppFilters, removeFilterAppMarket, resetAppFilters } = useCatalogueFilters()

  const calculatedTags = useMemo(() => {
    const categoryTags = appFilters.categories
      .map(item => ({
        label: t(`applications|application_categories.${ApplicationCategoryLabel[item]}.title`) as string,
        code: item,
        category: 'categories' as CategoryCode,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    const availabilityTags = []
    const commercialModelTags = []
    const globalRegionTags = []
    if (appFilters.isAvailable) {
      availabilityTags.push({
        label: t('applications|filters.activated_on_my_tenant'),
        code: 'isAvailable',
        category: 'isAvailable' as CategoryCode,
      })
    }
    if (appFilters.commercialModels) {
      commercialModelTags.push(
        ...appFilters.commercialModels.map(commercialModel => ({
          label: t(`applications|${CommercialModelLabel[commercialModel]}`),
          code: commercialModel,
          category: 'commercialModels' as CategoryCode,
        })),
      )
    }
    if (appFilters.globalRegion) {
      globalRegionTags.push({
        label: t('applications|regions.GLOBAL'),
        code: 'globalRegion',
        category: 'globalRegion' as CategoryCode,
      })
    }
    const tenantTags = Object.values(tenants)
      .filter(tenant => appFilters.tenantIds.includes(tenant.id))
      .map(tenant => ({ label: tenant.name, code: tenant.id, category: 'tenantIds' as CategoryCode }))
      .sort((a, b) => a.label.localeCompare(b.label))

    const marketTags = markets
      .filter(market => appFilters.markets.includes(market.countryCode))
      .map(market => ({ label: market.countryTitle, code: market.countryCode, category: 'markets' as CategoryCode }))
      .sort((a, b) => a.label.localeCompare(b.label))

    const allTags = [
      ...categoryTags,
      ...availabilityTags,
      ...commercialModelTags,
      ...globalRegionTags,
      ...marketTags,
      ...tenantTags,
    ]

    return {
      allTags,
      tagGroups: { categoryTags, availabilityTags, commercialModelTags, globalRegionTags, marketTags, tenantTags },
    }
  }, [appFilters, tenants, markets, t])

  const removeFilter = (code: CategoryCode, value: string) => {
    if (code === 'isAvailable' || code === 'globalRegion') {
      setAppFilters({ [code]: null })
      return
    }
    if (code === 'markets') {
      removeFilterAppMarket(value)
      return
    }
    const iterableCode = code as IterableFilterCode
    setAppFilters({ [iterableCode]: appFilters[iterableCode]?.filter(item => item !== value) })
  }

  const expandButtonShown = useDebouncedValue(
    (!!firstLineTagsAmount && firstLineTagsAmount < calculatedTags.allTags.length) || isTagsExpanded,
    1,
  )

  return (
    <S.FiltersTagsWrapper data-testid="filter-tags">
      <Flex direction="row" align="center" justify="between" gap={4}>
        <S.ResultsLabel isLoading={isLoading} type="m-body" data-testid="result-text">
          {t('applications|filters.filters_count', { count: resultsNumber })}
          {isSearchApplied && (
            <S.SearchKeyword tag="p" type="m-strong">
              "{appFilters.search}"
            </S.SearchKeyword>
          )}
        </S.ResultsLabel>
        <ApplicationLineOfTags
          tags={calculatedTags.allTags}
          onClose={removeFilter}
          shown={!isTagsExpanded}
          setTagAmount={setFirstLineTagsAmount}
        />
        <Flex>
          <S.ExpandButton
            shown={expandButtonShown}
            onClick={() => setIsTagsExpanded(!isTagsExpanded)}
            data-testid="toggle-filters"
          >
            {t(isTagsExpanded ? 'common|show_less' : 'common|show_all')} &nbsp;
            {!isTagsExpanded && (
              <span>({t('common|formatted_number', { value: calculatedTags.allTags.length, lng: numberLocale })})</span>
            )}
            <WppIconChevron slot="icon-end" />
          </S.ExpandButton>
          <WppActionButton variant="secondary" onClick={resetAppFilters} data-testid="reset-filters">
            {t('applications|filters.reset')}
          </WppActionButton>
        </Flex>
      </Flex>
      <S.TagsContainer className={isTagsExpanded ? 'shown' : 'hidden'} data-testid="tag-groups-wrapper">
        <ApplicationAllTags tagGroups={calculatedTags.tagGroups} removeTag={removeFilter} />
      </S.TagsContainer>
    </S.FiltersTagsWrapper>
  )
}
