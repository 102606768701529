import { Children, memo, PropsWithChildren, useMemo } from 'react'

import { ApplicationReviewScore, CombinedApplicationDTO } from 'types/applications/application'
import { MayBeNull } from 'types/common/utils'
import { ApplicationCardReviews } from 'ui-shared/applicationCard/components/ApplicationCardReviews'
import { CertificationLevelSticker } from 'ui-shared/certificationLevelSticker/CertificationLevelSticker'

import * as S from 'ui-shared/applicationCard/ApplicationCard.styled'

interface Props {
  application: CombinedApplicationDTO
  reviewScore?: MayBeNull<ApplicationReviewScore>
  isScoreLoading?: boolean
  hideEmptyReviews?: boolean
  isBrief?: boolean
  dataTestId?: string
}

export const ApplicationCardFooter = memo(
  ({
    application,
    reviewScore,
    isScoreLoading,
    hideEmptyReviews,
    isBrief,
    dataTestId,
    children,
  }: PropsWithChildren<Props>) => {
    const { overallReviewScore, reviewCount } = useMemo(() => {
      if (reviewScore) {
        return {
          overallReviewScore: reviewScore.overallScore,
          reviewCount: reviewScore.reviewCount,
        }
      }
      if (
        'overallReviewScore' in application &&
        !!application.overallReviewScore &&
        'reviewCount' in application &&
        !!application.reviewCount
      ) {
        return {
          overallReviewScore: application.overallReviewScore,
          reviewCount: application.reviewCount,
        }
      }
      return {
        overallReviewScore: null,
        reviewCount: null,
      }
    }, [reviewScore, application])

    return (
      <S.ApplicationCardFooter data-testid={dataTestId}>
        {'certification' in application && application.certification && (
          <CertificationLevelSticker
            certificationLevel={application.certification}
            expectedReleaseDate={application.expectedReleaseDate}
            tagLike
          />
        )}

        {!isBrief && (!hideEmptyReviews || (hideEmptyReviews && reviewCount)) && (
          <ApplicationCardReviews
            overallReviewScore={overallReviewScore}
            reviewCount={reviewCount}
            isScoreLoading={isScoreLoading}
          />
        )}

        {!isBrief && Children.map(children, child => <>{child}</>)}
      </S.ApplicationCardFooter>
    )
  },
)
