import { WppTag } from '@platform-ui-kit/components-library-react'
import { RangeOf } from '@platform-ui-kit/components-library/dist/types/types/numberRange'
import { MayBeNull } from '@wpp-open/core'
import dayjs from 'dayjs'
import quarter from 'dayjs/plugin/quarterOfYear'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CertificationLevel } from 'types/applications/enums'
import {
  CertificationLevelDescription,
  CertificationLevelLabel,
  DescribedCertificationLevels,
} from 'types/applications/labels'
import { Flex } from 'ui-base/flex/Flex'
import { CertifiedSvg } from 'ui-base/svgIcons/certificationLevel/assets/CertifiedSvg'
import { ComingSoonSvg } from 'ui-base/svgIcons/certificationLevel/assets/ComingSoonSvg'
import { GarageSvg } from 'ui-base/svgIcons/certificationLevel/assets/GarageSvg'
import { StandardSvg } from 'ui-base/svgIcons/certificationLevel/assets/StandardSvg'
import { Tooltip } from 'ui-base/tooltip/Tooltip'

import * as S from 'ui-shared/applicationCard/ApplicationCard.styled'

dayjs.extend(quarter)

interface Props {
  certificationLevel: CertificationLevel
  expectedReleaseDate: MayBeNull<string>
  tagLike?: boolean
}

const TAG_PROPS = {
  [CertificationLevel.CERTIFIED]: { categoricalColorIndex: 1, icon: <CertifiedSvg slot="icon-start" /> },
  [CertificationLevel.COMING_SOON]: { categoricalColorIndex: 9, icon: <ComingSoonSvg slot="icon-start" /> },
  [CertificationLevel.GARAGE]: { categoricalColorIndex: 5, icon: <GarageSvg slot="icon-start" /> },
  [CertificationLevel.STANDARD]: { categoricalColorIndex: 8, icon: <StandardSvg slot="icon-start" /> },
}

export const CertificationLevelSticker = ({ certificationLevel, expectedReleaseDate, tagLike }: Props) => {
  const { t } = useTranslation(['applications'])
  const parentRef = useRef<HTMLDivElement>(null!)
  const isExpectedDateProvided = certificationLevel === CertificationLevel.COMING_SOON && expectedReleaseDate
  const isExpectedReleaseDateOverdue = dayjs().isAfter(dayjs(expectedReleaseDate))
  const releaseDateShouldBeShown = isExpectedDateProvided && !isExpectedReleaseDateOverdue
  const quarterString = useMemo(() => {
    const dayjsInstance = dayjs(expectedReleaseDate)
    return `Q${dayjsInstance.quarter()} ${dayjsInstance.year()}`
  }, [expectedReleaseDate])

  return (
    <S.CertificationLevelWrapper ref={parentRef}>
      <Tooltip
        show
        text={
          isExpectedDateProvided
            ? t('applications|release_date_tooltip', { quarter: quarterString })
            : t(`applications|${CertificationLevelDescription[certificationLevel as DescribedCertificationLevels]}`)
        }
        data-testid={`certification-level-tooltip-${certificationLevel}`}
        config={{ placement: 'bottom' }}
        appendToParent
        parentRef={parentRef}
      >
        {tagLike ? (
          <Flex gap={8} align="center">
            <WppTag
              data-testid={`certification-level-tag-${certificationLevel}`}
              label={t(`applications|${CertificationLevelLabel[certificationLevel]}`)}
              categoricalColorIndex={TAG_PROPS[certificationLevel].categoricalColorIndex as RangeOf<9>}
            >
              {TAG_PROPS[certificationLevel].icon}
            </WppTag>
            {releaseDateShouldBeShown && (
              <S.ExpectedReleaseDate type="xs-body" data-testid="expected-release-date">
                {quarterString}
              </S.ExpectedReleaseDate>
            )}
          </Flex>
        ) : (
          <span data-testid={`certification-level-tag-${certificationLevel}`}>
            {t(`applications|${CertificationLevelLabel[certificationLevel]}`)}
          </span>
        )}
      </Tooltip>
    </S.CertificationLevelWrapper>
  )
}
