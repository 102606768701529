import { WppIconGlobe } from '@platform-ui-kit/components-library-react'
import { Tenant } from '@wpp-open/core'
import { MouseEvent, PropsWithChildren, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CombinedApplicationDTO } from 'types/applications/application'
import { Tooltip } from 'ui-base/tooltip/Tooltip'
import { ApplicationCardActions } from 'ui-shared/applicationCard/components/ApplicationCardActions'
import { ApplicationCardFooter } from 'ui-shared/applicationCard/components/ApplicationCardFooter'
import { ApplicationLogoPlain } from 'ui-shared/applicationLogo/ApplicationLogoPlain'
import { MadeByLabel } from 'ui-shared/madeByLabel/MadeByLabel'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'ui-shared/applicationCard/ApplicationCard.styled'

type Props = PropsWithChildren<{
  application: CombinedApplicationDTO
  tenant?: Tenant
  isRelatedApp?: boolean
  hideEmptyReviews?: boolean
}>

export const ApplicationCard = ({
  application,
  tenant,
  isRelatedApp = false,
  hideEmptyReviews = false,
  children: actions,
}: Props) => {
  const { id, isAvailable, name, shortDescription, applicationCategory, logoMeta, regions } = application
  const { t } = useTranslation(['applications'])
  const isGloballyAvailable = !regions.length

  const actionsRef = useRef<HTMLDivElement>(null)
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const isButtonClicked = actionsRef.current?.contains(event.target as Node)
    if (isButtonClicked) {
      event.preventDefault()
    }
  }

  return (
    <S.Link to={RoutesManager.applications.view.getURL({ id })} onClick={handleClick}>
      <S.ApplicationCard size="s" interactive data-testid="application-card">
        <S.Header gap={12} slot="header">
          <ApplicationLogoPlain logoMeta={logoMeta} />

          <S.HeaderNamePart justify="between">
            <S.ApplicationNameWrapper gap={2} direction="column">
              <Tooltip
                show
                showOnOverflow
                getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
                text={name}
                data-testid="name-tooltip"
              >
                <S.ApplicationName type="l-strong" tag="h2" data-testid="application-name">
                  {name}
                </S.ApplicationName>
              </Tooltip>
              <MadeByLabel tenant={tenant} applicationCategory={applicationCategory} />
            </S.ApplicationNameWrapper>

            <ApplicationCardActions isAvailable={isAvailable}>
              <div ref={actionsRef}>{actions}</div>
            </ApplicationCardActions>
          </S.HeaderNamePart>
        </S.Header>

        <S.ApplicationCardContent>
          <Tooltip
            show
            showOnOverflow
            getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
            text={shortDescription}
            data-testid="description-tooltip"
          >
            <S.ApplicationDescription type="s-body" data-testid="application-description">
              {shortDescription}
            </S.ApplicationDescription>
          </Tooltip>
        </S.ApplicationCardContent>

        <ApplicationCardFooter application={application} isBrief={isRelatedApp} hideEmptyReviews={hideEmptyReviews}>
          {isGloballyAvailable && !isRelatedApp && (
            <Tooltip
              show
              text={t('applications|global_tooltip')}
              config={{ placement: 'bottom' }}
              data-testid="card-global-availability-tooltip"
            >
              <WppIconGlobe data-testid="card-icon-globe" />
            </Tooltip>
          )}
        </ApplicationCardFooter>
      </S.ApplicationCard>
    </S.Link>
  )
}
