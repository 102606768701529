import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { Tenant } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { ApplicationDTO } from 'types/applications/application'
import { ApplicationCategoryLabel } from 'types/applications/labels'
import { MayBeNull } from 'types/common/utils'
import { Flex } from 'ui-base/flex/Flex'
import { VerticalDivider } from 'ui-shared/verticalDivider/VerticalDivider'

import * as S from 'ui-shared/madeByLabel/MadeByLabel.styled'

interface Props {
  applicationCategory: ApplicationDTO['applicationCategory']
  tenant?: MayBeNull<Tenant>
  type?: TypographyType
}

export const MadeByLabel = ({ applicationCategory, tenant, type = 'xs-body' }: Props) => {
  const { t } = useTranslation(['common', 'applications'])
  const category = applicationCategory?.category
  const showTenant = !!tenant?.id || !!tenant?.name

  if (!showTenant && !category) {
    return <S.AgencyPlaceholder />
  }

  return (
    <Flex align="center">
      <S.Category type={type} data-testid="application-category">
        {t(`applications|application_categories.${ApplicationCategoryLabel[category]}.title`)}
      </S.Category>
      {showTenant && (
        <>
          <VerticalDivider margin="0 4px" height={16} />

          <S.Tooltip
            show
            showOnOverflow
            getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
            text={tenant?.name}
          >
            <S.Agency type={type} data-testid="agency-label">
              {t('by', { target: tenant?.name })}
            </S.Agency>
          </S.Tooltip>
        </>
      )}
    </Flex>
  )
}
