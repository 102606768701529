import {
  FilterGroupNames,
  RemoveFilter,
  TagLabel,
} from 'pages/applications/list/components/filterTags/ApplicationFiltersTags'
import { ApplicationTagsGroup } from 'pages/applications/list/components/filterTags/components/ApplicationTagsGroup'
import { Flex } from 'ui-base/flex/Flex'

type Props = {
  tagGroups: Record<FilterGroupNames, TagLabel[]>
  removeTag: RemoveFilter
}

export const ApplicationAllTags = ({ tagGroups, removeTag }: Props) => {
  const { categoryTags, availabilityTags, commercialModelTags, marketTags, tenantTags } = tagGroups

  return (
    <Flex direction="column" gap={16} data-testid="tag-groups">
      {categoryTags.length > 0 && (
        <ApplicationTagsGroup
          message={'applications|filters.categories'}
          tags={categoryTags}
          onClose={removeTag}
          group="categories"
        />
      )}
      {availabilityTags.length > 0 && (
        <ApplicationTagsGroup
          message={'applications|filters.availability'}
          tags={availabilityTags}
          onClose={removeTag}
          group="isAvailable"
        />
      )}
      {commercialModelTags.length > 0 && (
        <ApplicationTagsGroup
          message={'applications|filters.commercial_model'}
          tags={commercialModelTags}
          onClose={removeTag}
          group="commercialModel"
        />
      )}
      {tenantTags.length > 0 && (
        <ApplicationTagsGroup
          message={'applications|filters.developed_by'}
          tags={tenantTags}
          onClose={removeTag}
          group="developedBy"
        />
      )}
      {marketTags.length > 0 && (
        <ApplicationTagsGroup
          message={'applications|filters.market_other'}
          tags={marketTags}
          onClose={removeTag}
          group="markets"
        />
      )}
    </Flex>
  )
}
