import styled from '@emotion/styled'
import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Tooltip as CustomTooltip } from 'ui-base/tooltip/Tooltip'
import { Truncate } from 'ui-base/truncate/Truncate'

export const AgencyPlaceholder = styled('div')`
  height: 20px;
`

export const Agency = styled(Truncate)`
  color: var(--wpp-grey-color-800);
  &::part(typography) {
    display: block;
  }
`

export const Category = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`

export const Tooltip = styled(CustomTooltip)`
  min-width: 0;
`
